<template>
  <form class="flex items-center justify-center min-h-screen min-w-screen" @submit.prevent="submit">
    <div class="flex flex-col items-center justify-center">
      <img :src="logo.src" alt="Altipla Consulting" class="mb-5 w-24 flex-shrink-0" />
      <div class="rounded-[56px] p-1 w-[500px]" style="background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
        <div class="bg-white py-12 px-5 sm:px-12 rounded-[53px] flex flex-col gap-5">
          <div>
            <label for="email" class="block text-gray-900 font-medium mb-1">Correo electrónico</label>
            <InputText
              id="email"
              type="text"
              class="w-full p-3"
              v-model="form.email"
              autocomplete="email"
              :invalid="!!errors.email"
              @update:model-value="revalidate"
              autofocus
            />
            <p class="text-red-600 mt-1 text-sm">{{ errors.email }}</p>
          </div>

          <div>
            <label for="password" class="block text-gray-900 font-medium mb-1">Contraseña</label>
            <Password
              id="password"
              :feedback="false"
              v-model="form.password"
              class="w-full"
              input-class="w-full p-3"
              :input-props="{ autocomplete: 'current-password' }"
              :invalid="!!errors.password"
              @update:model-value="revalidate"
            ></Password>
            <p class="text-red-600 mt-1 text-sm">{{ errors.password }}</p>
          </div>

          <Button type="submit" label="Iniciar sesión" class="w-full p-3 text-xl mt-5" :loading="sending"></Button>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { TRPCClientError } from '@trpc/client'
import Button from 'primevue/button'
import Password from 'primevue/password'
import InputText from 'primevue/inputtext'
import { reactive } from 'vue'
import { useAPI } from '~/composables/api'
import { useForm } from '~/composables/form'
import logo from '~/images/logo.png'
import cookies from 'js-cookie'

let client = useAPI()

let form = reactive({
  email: '',
  password: '',
})

let { errors, revalidate, sending, submit } = useForm({
  onSubmit: async ({ setError }) => {
    let reply
    try {
      reply = await client.auth.login.mutate(form)
    } catch (err) {
      if (err instanceof TRPCClientError) {
        if (err.message === 'email not found') {
          setError('email', 'El correo electrónico no existe.')
          return
        }
        if (err.message === 'incorrect password') {
          setError('password', 'La contraseña es incorrecta.')
          return
        }
      }
      throw err
    }

    cookies.set('portal-auth', reply.token, {
      expires: reply.expiration,
    })
    location.href = '/'

    // Never ending promise to keep the button in the loading state.
    await new Promise(() => {})
  },
  onValidate: ({ setError }) => {
    if (!form.email) {
      setError('email', 'El correo electrónico es obligatorio.')
    }
    if (form.email && !form.email.includes('@')) {
      setError('email', 'El correo electrónico no tiene un formato válido.')
    }
    if (!form.password) {
      setError('password', 'La contraseña es obligatoria.')
    }
    if (form.password && form.password.length < 8) {
      setError('password', 'La contraseña no tiene un formato válido.')
    }
  },
})
</script>
